import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const complaint = {
  state: {
    // datastore: null,
  },
  getters: {
    // datastore:state=>state.datastore,
  },
  actions: {
    async InsertComplaintData({ commit }, payload) {
        console.log('====>')
        console.log(payload)
      try {
        const response = await axios.post(
          "ControlComplaint",
          payload
        );
   
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async UpdateDataComplaintID({ commit }, payload) {
      try {
        const response = await axios.put(
          `ControlComplaint/${payload.ShiftID}`,
          payload
        );
        
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getComplaintData() {
     
      try {
        const response = await axios.get(
          "ControlComplaint"
        );
       
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async deleteComplaintData({ commit },id) {
   
      try {
        const response = await axios.delete(
          `ControlComplaint/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
  },
};
export default complaint;
