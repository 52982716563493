import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";
import io from 'socket.io-client';

const store_evaluation = {
  state: {
    // dataevalu : null,
    // dataSettingEvalution : null
    // datastore: null,
  },
  getters: {
    // datastore:state=>state.datastore,
  },
  actions: {
    async GetTime_evaluation({ commit }) {
      //console.log('--===>')
      try {
        const response = await axios.get(
          "ControlSetEvaluation"
        );
        return response.data;
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async GetTime_Evaluation_id({ commit },ID) {
  
      try {
        const response = await axios.get(
          `ControlSetEvaluation/${ID}`
        );
        // commit("setDataSettingEvalu", response.data);
      
        return response.data;
     
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async InsertTime_evaluation({ commit }, payload) {
     
      try {
        const response = await axios.post(
          "ControlSetEvaluation",
          payload
        );
      return;
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async UpdateTime_evaluation({ commit }, payload) {

      try {
        const response = await axios.put(
          `ControlSetEvaluation/${payload.ID}`,
          payload
        );
        //console.log('===>'+payload.ID);
       
        return
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
 
    async deleteTime_evaluation({ commit },id) {
     
      try {
        const response = await axios.delete(
          `ControlSetEvaluation/${id}`
        );
        return response;
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    //  setDataevalu(state, dataevalu) {
    //    state.dataevalu = dataevalu;
    //  },
    //  setDataSettingEvalu(state, dataSettingEvalution) {
    //   state.dataSettingEvalution = dataSettingEvalution;
    // },
  },
};
export default store_evaluation;
