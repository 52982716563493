import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const coupons = {
  state: {
    // dataCoupon: null,
  },
  getters: {
    // dataCoupon:state=>state.dataCoupon,
  },
  actions: {
    async couponsData({ commit }, payload) {

      try {
        const response = await axios
          .post("Controlcoupons", payload.from)
          .then((response) => {
            const id = response.data;
            for (const property in payload.cbxs.cbxs_t) {
              // console.log(`${property}: ${payload.cbxs.cbxs_t[property]}`);
              const datacheck = {
                priodID: payload.cbxs.cbxs_t[property].split("-")[1],
                Shift: payload.cbxs.cbxs_t[property].split("-")[0],
                cou_id: id,
              };
           
              this.dispatch("CouponTimeData", datacheck);
            }
          });

      } catch (error) {
        console.log(error);
      }
    },
    async UpdateDataCouponsID({ commit }, payload) {
    // console.log(payload)
      try {
        const response = await axios
          .put(`Controlcoupons/${payload.from.cou_id}`, payload.from)
          .then((response) => {
           
            this.dispatch("deleteCouponTimeData", payload.from.cou_id).then(
              (success) => {
               
                const id = payload.from.cou_id;
                for (const property in payload.cbxs.cbxs_t) {
                 
                  const datacheck = {
                
                    priodID: payload.cbxs.cbxs_t[property].split("-")[1],
                    Shift: payload.cbxs.cbxs_t[property].split("-")[0],
                    cou_id: id,
                  };
                  this.dispatch("CouponTimeData", datacheck);
                }
              }
            );
          });
        // console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getcouponsData() {
      try {
        const response = await axios.get(
          "Controlcoupons"
        );
      
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async deletecouponsData({ commit }, id) {
      
      try {
        const response = await axios.delete(
          `Controlcoupons/${id}`
        );
        this.dispatch("deleteCouponTimeData", id);
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    // setData(state, dataCoupon) {
    //   state.dataCoupon = dataCoupon;
    // },
  },
};
export default coupons;
