import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";
import io from 'socket.io-client';

const magestore = {
  state: {
    // datastore: null,
  },
  getters: {
    // datastore:state=>state.datastore,
  },
  actions: {
    async GetdataStore({ commit }) {
      try {
        const response = await axios.get(
          "Controlstore",
          payload
        );
        return response.data;
        console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async magestoreData({ commit }, payload) {
      try {
        const response = await axios.post(
          "Controlstore",
          payload
        );
        
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async UpdateDataMageStoreID({ commit }, payload) {

      try {
        const response = await axios.put(
          `Controlstore/${payload.storeID}`,
          payload
        );
        console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getmagestoreData() {
     
      try {
        const response = await axios.get(
          "Controlstore"
        );
     
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async deletemagestoreData({ commit },id) {
    
      try {
        const response = await axios.delete(
          `Controlstore/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    // setData(state, datastore) {
    //   state.datastore = datastore;
    // },
  },
};
export default magestore;
