import Vue,{ createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios'


import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'jszip';
import 'pdfmake';
import 'datatables.net-buttons-bs5'
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css'
import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-buttons/js/buttons.flash'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-responsive'
// import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5.min.mjs'
import 'datatables.net-buttons/js/buttons.print'

import 'datatables.net-dt/css/jquery.dataTables.css';
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'

import { faPrint, faPlus, faUserTie, faTicket, faArrowUpFromBracket, faBusinessTime, faShopLock,faHourglassEnd,faLayerGroup,faGroupArrowsRotate,faAngleRight,faRightToBracket,faEyeSlash, faEye,faCircle,faCircleInfo,faArrowsLeftRightToLine,faClock,faRotateRight,faWallet,faBarcode,faCamera,faRepeat,faCircleXmark  } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faKey, faFileCircleCheck } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos'
import Antd from "ant-design-vue";

// import "aos/dist/aos.css"

/* add icons to the library */
library.add( faUserSecret,faLanguage,faPrint, faPlus, faUserTie, faTicket, faArrowUpFromBracket, faBusinessTime, faShopLock,faHourglassEnd,faLayerGroup,faGroupArrowsRotate,faAngleRight,faStar, faKey, faFileCircleCheck,faRightToBracket,faEyeSlash, faEye,faCircle ,faCircleInfo,faArrowsLeftRightToLine,faClock,faRotateRight,faWallet,faBarcode,faCamera,faRepeat,faCircleXmark )



const lang = localStorage.getItem('lang') || 'en';
//axios.defaults.baseURL= 'http://localhost:3000';
// axios.defaults.baseURL= 'https://ecoupons.systemtd.com/';
axios.defaults.baseURL= 'https://pickup.systemtd.com/';
axios.defaults.headers['Accept-Language'] = lang; 


createApp(App)
.use(VueSweetalert2)
.use(store).use(router).component('font-awesome-icon', FontAwesomeIcon)
.use(Antd)
.mount('#app')
