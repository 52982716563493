<template>
  <div>
   
    <CouponsList />

    <FooterVue name="coupons" />
  </div>
</template>

<script>
import CouponsList from "@/components/CouponsList.vue";
import FooterVue from "./HeadFix/Footer.vue";
import Header from "@/components/Header.vue";


export default {
  name: "ECoupons",
  components: {
    FooterVue,
    CouponsList,
    Header,

  },
  methods:{
   
  },
 

};
</script>

<style>
</style>