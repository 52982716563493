import { createRouter, createWebHistory,createWebHashHistory  } from 'vue-router'
import HomeView from '../views/ECoupons.vue'
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/AboutView.vue')
  },{
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },{
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterUser.vue')
  },{
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfileUser.vue')
  },{
    path: '/ecoupons',
    name: 'ecoupons',
    component: () => import('../views/ECoupons.vue')
  },{
    path: '/ecouponsTail/:ID',
    name: 'ecouponsTail',
    component: () => import('../views/ECouponsTail.vue')
  },{
    path: '/store',
    name: 'store',
    component: () => import('../views/MageStore.vue')
  },{
    path: '/set',
    name: 'set',
    component: () => import('../views/Setting.vue')
  },{
    path: '/setUser',
    name: 'setUser',
    component: () => import('../views/SettingUser.vue')
  },{
    path: '/addUserArr',
    name: 'addUserArr',
    component: () => import('../views/SettingUserAddArray.vue')
  },{
    path: '/setStore',
    name: 'setStore',
    component: () => import('../views/SettingStore.vue')
  },{
    path: '/setTime',
    name: 'setTime',
    component: () => import('../views/SettingTime.vue')
  },{
    path: '/setCoupons',
    name: 'setCoupons',
    component: () => import('../views/SettingCoupons.vue')
  },{
    path: '/setTypeCoupons',
    name: 'setTypeCoupons',
    component: () => import('../views/SettingTypeCoupons.vue')
  },{
    path: '/setShift',
    name: 'setShift',
    component: () => import('../views/SettingShift.vue')
  },{
    path: '/stagement/:ID',
    name: 'stagement',
    component: () => import('../views/StoreStagement.vue')
  },{
    path: '/changepassword/:ID',
    name: 'changepassword',
    component: () => import('../views/ChangePassword.vue')
  }
  ,{
    path: '/profile_history/:ID',
    name: 'profile_history',
    component: () => import('../views/ProfileUserHistory.vue')
  }
,{
    path: '/setReport',
    name: 'setReport',
    component: () => import('../views/Report_Total.vue')
  },{
    path: '/setReportBoard',
    name: 'setReportBoard',
    component: () => import('../views/Report_TotalDash.vue')
  },{
    path: '/setReportComplaint',
    name: 'setReportComplaint',
    component: () => import('../views/Report_Complaint.vue')
  },{
    path: '/setUpload',
    name: 'setUpload',
    component: () => import('../views/Setting_upload.vue')
  },{
    path: '/setInsertUsed',
    name: 'setInsertUsed',
    component: () => import('../views/Add_ECoupons.vue')
  },{
    path: '/StoreAppraisal/:ID',
    name: 'StoreAppraisal',
    component: () => import('../views/StoreAppraisal.vue')
  },{
    path: '/ManualEcoupon',
    name: 'ManualEcoupon',
    component: () => import('../views/Manual/Index.vue')
  },{
    path: '/setTimeEvaluation',
    name: 'setTimeEvaluation',
    component: () => import('../views/SettingTimeEvaluation.vue')
  },{
    path: '/ReadScan/:IDstore/:ID_cou/:ID_period/:ResiveName',
    name: 'ReadScan',
    component: () => import('../views/BarcodeReader/IndexRead.vue')
  },{
    path: '/complaint',
    name: 'complaint',
    component: () => import('../views/complaint/complaint.vue')
  },{
    path: '/complaintView',
    name: 'complaintView',
    component: () => import('../views/complaint/complaintView.vue')
  } ,  { path: '/404', component: NotFound }, { path: '/:catchAll(.*)', redirect: '/404' },
]

const router = createRouter({
  history: createWebHashHistory(),
  // mode:'history',
  
//  base:process.env.BASE_URL,
  //  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
