import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const coupons = {
  state: {
    // dataCoupon: null,
  },
  getters: {
    // dataCoupon:state=>state.dataCoupon,
  },
  actions: {
    async usecouponsData({ commit }, payload) {
      try {

        const response = await axios
          .post("ControlcouponsAction", payload)
          .then((response) => {
       
     
            return response;

          });

     
      } catch (error) {
        console.log(error);
      }
    },
    async UpdateCouponsData({ commit }, payload) {


      const ID = payload.map_id
      try {
        const response = await axios
          .put(`ControlcouponsActionUpdate/${ID}`, payload)
          .then((response) => {     
            return response;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async CancelDataCouponsID({ commit }, payload) {
  //  console.log(payload)
      try {
        const response = await axios
          .put(`ControlcouponsAction/${payload.map_id}`, payload)
          .then((response) => {
            return;
        
          });
     
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async DeleteDataCouponsID({ commit }, payload) {
  
         try {
           const response = await axios
             .delete(`ControlcouponsAction/${payload}`)
             .then((response) => {
               return;
            
             });
        
         } catch (error) {
           console.log(error.response.data);
         }
       },
  
  },

  mutations: {
    // setData(state, dataCoupon) {
    //   state.dataCoupon = dataCoupon;
    // },
  },
};
export default coupons;
