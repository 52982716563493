<template >
  <Header msg="E-Coupons" />
  <TimecurrentVue/>
  <!-- Just an image -->
  <!-- v-if="$store.state.ac_loging.datauser.Shift!=''" -->
  <div class="container"  >
    {{JSON.stringify(cardscoupons)}}
    <div v-for="datatype in cardscoupons" :key="datatype.type" style="">

      <div class="bar-categiry" :style="`background: linear-gradient( 292deg, var(--bs-warning) 10%, ${datatype.dataTimeAll[0].datacou.cou_type_color} 57%)`">{{ datatype.type_desc }}</div>

      <div class="row" style="text-align: -webkit-center;">
        <div
          class="col-sm-6 col-md-4 col-xl-3 card-menu"
          v-for="card in datatype.dataTimeAll"
          :key="card.datacou.cou_id"
          @click="eventCoupons(card.datacou.cou_id)"
        >
        <hr class="line-coupon">
   <CardCouponsVue :datacard="card.datacou" :isTimeVis="card.isTimeVis"/>
       
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else>
<h1>No Coupons</h1>
  </div> -->
</template>
    
    <script>
import Header from "./Header.vue";
import Store_barcodeVue from "./Store_barcode.vue";
import { mapActions } from "vuex";
import axios from "axios";
import TimecurrentVue from './Timecurrent.vue';
import CardCouponsVue from './CardCoupons.vue';


export default {
  name: "couponsList",
  components: {
    Store_barcodeVue,
    Header,
    TimecurrentVue,
    CardCouponsVue,
  },
  data() {
    return {
      cardscoupons: [],
      cards: [
        {
          id: 1,
          title: "อาหาร",
          price: "มูลค่า  30.-",
          image: "https://via.placeholder.com/300x200",
          text: "0/3",
          type: "food",
        },
        {
          id: 2,
          title: "เครื่องดื่ม",
          price: "มูลค่า  30.-",
          image: "https://via.placeholder.com/300x200",
          text: "This is a short",
          type: "food",
        },
        {
          id: 3,
          title: "บริการเครื่องซักผ้า",
          price: "มูลค่า  30.-",
          image: "https://via.placeholder.com/300x200",
          text: "This is a short",
          type: "serve",
        },
        {
          id: 1,
          title: "Card 1",
          price: "มูลค่า  30.-",
          image: "https://via.placeholder.com/300x200",
          text: "This is a short",
          type: "serve",
        },
      ],
   
    };
  },
  methods: {
    ...mapActions(["time", "deletetimeData"]),
    async eventCoupons(ID) {
      // alert(ID)
    //  this.$router.push( "ecouponsTail/"+ID);
       this.$router.push({ name: "ecouponsTail", params: {ID } });
    },
    async getdatacoupons() {
      try {
       
        const response = await axios
          .get("Controlcoupons")
          .then((response) => {
        
       console.log('-----DATA--------',response.data)
            this.cardscoupons = response.data;
          });
      } catch (err) {
        console.log(err);
      }
    },
    checkCurrentItem() {
      const now = new Date();
      const datetext = String(now.getHours()).padStart(2,'0')+":"+String(now.getMinutes()).padStart(2,'0')+":"+String(now.getSeconds()).padStart(2,'0');
    for (const item of this.cardscoupons) {
      if (Array.isArray(item.dataTimeAll)) {
     for (const item2 of item.dataTimeAll) {
//  console.log(item2.datacou.cou_id)  
 item2.isTimeVis = false
      for (const itemTime of item2.couponAndTime) {
            if (datetext >= itemTime.peiod_strat && datetext <= itemTime.peiod_end) {
              item2.isTimeVis = true;
           
              break;
          // return;
        }
      }
    }
  }
    }
    // If the current time is after the last time in the array,
    // return false.
    return false;
      // ตย
      // const now = new Date();
      // for (const item of this.items) {
      //   if (now >= item.startdate && now <= item.enddate) {
      //     this.currentItem = item;
      //     return;
      //   }
      // }
      // this.currentItem = null;
    }
  },
  mounted() {
    this.getdatacoupons();
  },

  watch:{
     // Call the checkCurrentItem method every second
     '$options.methods.checkCurrentItem': {
      handler() {
        setInterval(this.checkCurrentItem, 1000);
      },
      immediate: true
    }
  }
};
</script>
  <style scoped>
.text-menu {
  font-size: x-small;
  text-decoration-line: none;
  color: black;
}
.card-menu {
  margin-top: 5px;
  margin-bottom: 4px;
  /* padding: 5px !important; */
  min-height: 115px;
  /* max-height: 100px; */
}
.img-in-card-l1 {
  position: absolute;
  top: -27px;
  left: -14px;
  z-index: 1;
  width: 200px;
}
.img-in-card-l2 {
  /* position: absolute;
  top: 1px;
  left: 5px; */
  z-index: 2;
  height: 120%;
  opacity: 0.9;
}
.text-coupon {
  text-align-last: right;
  position: relative;
  z-index: 50;
}
.font-count {
  position: absolute;
  right: 0;
  bottom: -17px;
  font-size: small;
  font-weight: 700;
}
.font-weight-bold {
  font-size: x-large;
  font-weight: 900;
}
.card {
  box-shadow: 0px 3px 4px #0000006e;
  transition: 0.5s;
  padding: 3px;
  border: none;
  cursor: pointer;
}
.card-body {
  border: 1px dashed #ccc;
}
.card:hover {
  background: #fffebf;
  box-shadow: 1px 5px 7px #0000006e;
  transition: 1s;
}
.card-body {
  position: relative;
  overflow: hidden;
}

.container {
  overflow: hidden;
}
.bar-categiry {
  /* background: linear-gradient( 292deg, var(--bs-warning) 10%, rgba(245, 117, 75, 1) 57%, rgba(226, 90, 45, 1) 74%, rgba(245, 117, 75, 1) 88% ); */
  /* background: #f5754b; */
  color: #ffffff;
  border-radius: 1px;
  text-align: left;
  padding-left: 7px;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: 5px;
}
.disabled{
  filter: grayscale(1);
    /* pointer-events: none;
    cursor: not-allowed;
    cursor: no-drop; */
}


.line-coupon{
  border: 1px dashed #222;
    /* padding: 0; */
    margin-bottom: 8px;
    margin-top: 0;
}

</style>
    