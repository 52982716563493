<template>
  <div
    class="modal fade"
    id="barcodeModal"
    tabindex="-1"
    ref="barcodeModal"
    aria-labelledby="barcodeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content rounded-4 shadow">
        <div class="modal-header p-5 pb-4 border-bottom-0">
          <h5 class="modal-title" id="barcodeModalLabel">บาร์โค๊ดร้านค้า</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
       
          <div class="modal-body printable">
            <div class="printable-div" ref="printableDiv">
              ร้าน {{ myData.storeName }}
          <div class="card rounded-4">
            <div class="card-body">
              <div class="card-bar">{{ myData.storeID}}</div>
            </div>
          </div>
        </div>
        <br>
          <button type="button" class="btn btn-primary" @click="printDiv">
            Print
          </button>
        </div>
        
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    myData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    printDiv() {
      let printContents = this.$refs.printableDiv.innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
  },
};
</script>
<style   scoped>
@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+128+Text&family=Mali:wght@300&display=swap");
@media print {
  .printable-div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.card-bar {
  font-size: 160px;
  font-family: "Libre Barcode 128 Text", cursive;
}
</style>