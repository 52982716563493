import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const time = {
  state: {
    // dataTime: null,
  },
  getters: {
    // dataTime:state=>state.dataTime,
  },
  actions: {
    async timeData({ commit }, payload) {

      try {
        const response = await axios.post(
          "Controltime",
          payload
        );
        
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async UpdateDataTimeID({ commit }, payload) {
      
      try {
        const response = await axios.put(
          `Controltime/${payload.priodID}`,
          payload
        );
      
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async gettimeData() {
     
      try {
        const response = await axios.get(
          "Controltime"
        );
       
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async deletetimeData({ commit },id) {
      
      try {
        const response = await axios.delete(
          `Controltime/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    // setData(state, dataTime) {
    //   state.dataTime = dataTime;
    // },
  },
};
export default time;
