import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";
import io from 'socket.io-client';

const store_evaluation = {
  state: {
    dataevalu : null,
    dataSettingEvalution : null
    // datastore: null,
  },
  getters: {
    // datastore:state=>state.datastore,
  },
  actions: {
    async Getstore_evaluation({ commit }) {
      try {
        const response = await axios.get(
          "Controlstoreevaluation"
        );
        commit("setDataevalu", response.data);
      
        return response.data;
       
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async GetEvaluation_setting({ commit },ID) {
      try {
        const response = await axios.get(
          `ControlevaluationSet/${ID}`
        );
        commit("setDataSettingEvalu", response.data);
      
        return response.data;
     
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async InsertStore_evaluation({ commit }, payload) {
      try {
        const response = await axios.post(
          "Controlstoreevaluation",
          payload
        );
      
        console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async Updatestore_evaluation({ commit }, payload) {

      try {
        const response = await axios.put(
          `Controlstoreevaluation/${payload.storeID}`,
          payload
        );
        console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getstore_evaluationData() {
     
      try {
        const response = await axios.get(
          "Controlstoreevaluation"
        );
        console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async deletestore_evaluation({ commit },id) {
     
      try {
        const response = await axios.delete(
          `Controlstoreevaluation/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
     setDataevalu(state, dataevalu) {
       state.dataevalu = dataevalu;
     },
     setDataSettingEvalu(state, dataSettingEvalution) {
      state.dataSettingEvalution = dataSettingEvalution;
    },
  },
};
export default store_evaluation;
