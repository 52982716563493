<template>
  <nav class="navbar navbar-expand-sm bg-light">
    <div class="container">
      <!-- <a class="navbar-brand" href="#">Brand</a> -->

      <div class="navbar-collapse justify-content-center" id="navbarNav">
        <a> {{ msg }}</a>

  
     
          <router-link to="/ManualEcoupon" target="_blank" class=" btn-lang" title='คู่มือ'>
<!-- Add Icons using String format -->
<font-awesome-icon style="    font-size: large;" icon="fa-solid fa-circle-info" />
<!-- <font-awesome-icon style="    font-size: large;" icon="fa-language" /> -->

          <!-- {{ language }} -->
        </router-link>


      </div>
    </div>
  </nav>
  
  <div class="nav-height"></div>
  
  <!-- Modal -->
</template>


<script>
//  import "@/assets/translate.js";
//  import "@/assets/translate_call.js";

// import translate from 'google-translate';
export default {
  name: "header",
  props: {
    msg: String,
  },
  data() {
    return {
      language: true,
    };
  },
  mounted() {
    this.language = localStorage.getItem("lang") || "true";

  
    
  },
  methods: {
   
    languageSelectedHandler(info) {
      console.log(info);
    },
    // shoemodallang() {
    //   // alert( this.language )
    //   this.language =  !Boolean(this.language);
    //   // alert( this.language )
    //   localStorage.setItem("lang",  this.language );
    //   // window.location.reload();
    // },


   
    
  },
};
</script>

<style scoped>
.navbar {
  box-shadow: 0px 3px 4px #00000069;
  position: fixed;
  width: 100%;
  z-index: 100;
}
.nav-height {
  height: 45px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.lang-text {
  width: 120px;
  position: absolute;
}
.btn-lang{
  position: absolute;
    left: 1%;
    top: 9%;
    transition: 0.5s;
}
.btn-lang:hover{
  background: var(--bs-warning);
  cursor: pointer;
  contain: 'Manual';
    border-radius: 5px;
    padding: 3px;
}
</style>