<template>
  {{ greetingMessage }}
  <footer class="text-white">
    <div class="container">
      <div
        class="row row-cols-4 align-items-center"
        style="place-content: center"
      >
      
        <router-link
          to="/store"
          class="text-white text-decoration-none decoration-none"
          v-show="
            this.$store.state.ac_loging.datauser.UserAccess === 'ADMIN' ||
            this.$store.state.ac_loging.datauser.UserAccess === 'STORE'
          "
        >
          <div :class="{ 'col btn-foot': true, active: !store }">
            <button class="btn menu-btn">
              <div class="row">
                <div class="col-md-4 none-pad">
                  <img
                    src="../../../public/Images/icons/market_commerce.png"
                    class="img-icon"
                    alt="Rounded image"
                  />
                </div>
                <div class="col-md-8 none-pad text-pad">
                  <span>ร้านค้า</span>
                </div>
              </div>
            </button>
          </div>
        </router-link>
        <router-link
          to="/"
          class="text-white text-decoration-none decoration-none"
          v-show="
         
            this.$store.state.ac_loging.datauser.UserAccess != 'STORE'
          "
        >
          <div :class="{ 'col btn-foot': true, active: !coupons }">
            <button class="btn menu-btn">
              <div class="row">
                <div class="col-md-4 none-pad">
                  <img
                    src="../../../public/Images/icons/ticket.png"
                    class="img-icon"
                    alt="coupons image"
                  />
                </div>
                <div class="col-md-8 none-pad text-pad"><span>คูปอง</span></div>
              </div>
            </button>
          </div>
        </router-link>
        <router-link
          to="/set"
          class="text-white text-decoration-none decoration-none"
          v-show="this.$store.state.ac_loging.datauser.UserAccess === 'ADMIN'"
        >
          <div :class="{ 'col btn-foot': true, active: !setting }">
            <button class="btn menu-btn">
              <div class="row">
                <div class="col-md-4 none-pad"><img
                src="../../../public/Images/icons/ui_ux_web_design.png "
                class="img-icon"
                alt="Rounded image"
              /></div>
                <div class="col-md-8 none-pad text-pad"> <span>จัดการข้อมูล</span></div>
              </div>
              
             
            </button>
          </div>
        </router-link>
        <router-link
          to="/profile"
          class="text-white text-decoration-none decoration-none"
        >
          <div :class="{ 'col btn-foot': true, active: !profile }">
            <button class="btn menu-btn">
              <div class="row">
                <div class="col-md-4 none-pad">
                  <img
                src="../../../public/Images/icons/user_profile.png"
                class="img-icon"
                alt="Rounded image"
              />
                </div>
                <div class="col-md-8 none-pad text-pad"> 
                  <span>ส่วนตัว</span>
                </div>
              </div>
              
             
            </button>
          </div>
        </router-link>
      </div>
    </div>
  </footer>

  <div class="navbar-height"></div>
</template>

<script>
export default {
  name: "foot",
  data() {
    return {
      profile: true,
      setting: true,
      coupons: true,
      store: true,
      ShowStore: true,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
 
  beforeCreate(){
    try {
      if (!this.$store.state.ac_loging.LoginVerified) {
      this.$router.replace({ name: "login" });
    }
    
    if (!this.$store.state.ac_loging.datauser.UserAccess) {
      this.$router.replace({ name: "login" });
    }
    
    } catch (error) {
     
      this.$router.replace({ name: "login" });
    }
  
  
  },
  mounted() {
    if (!this.$store.state.ac_loging.LoginVerified) {
      this.$router.replace({ name: "login" });
    }
   
  },

  computed: {
    greetingMessage() {
      this.profile = this.name === "profile" ? false : true;
      this.setting = this.name === "setting" ? false : true;
      this.coupons = this.name === "coupons" ? false : true;
      this.store = this.name === "store" ? false : true;
      // return `Hello, ${this.name}!`;
    },
  },
};
</script>

<style>
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  background: rgb(116, 0, 0);
  z-index: 100;
  color: #fff;
}

.active {
  background: #fff;
  transition: 0.5s;
  border-top: 1px solid #740000;
}

.menu-bf {
  height: 3rem;
  width: 4rem;
  position: relative;
}
.img-icon {
  height: 2.5rem;
}
.btn-content {
  position: absolute;
  top: -4px;
  padding: 0;
  left: 3px;
}
.btn-content a {
  font-size: small;
}
.nav-height {
  height: 100px;
}
.btn-foot:hover {
  background: #ffd555;
  color: black;

  transition: 0.5s;
}
.decoration-none {
  padding: 0 !important;
}
.navbar-height {
  height: 37px;
}
.text-pad{
  /* background: #fff; */
  overflow: hidden;
    /* width: 23px; */
    height: 20px;
    font-size: small;
    color: #fff;
    align-self: center;
}
.active .text-pad{
  color: #1f1f1f;
}
</style>
