<template>
  <div class="crop-time">

      <p class="time" v-text="currentTime"></p>
    </div>
</template>

<script>
import { format, formatDistance, formatRelative, subDays } from "date-fns";
const getCurrentTime = () => format(new Date(), "HH:mm:ss");
// const getCurrentTime = () => format(new Date(), "hh:mm:ss a");
export default {
    data(){
        return{
            currentTime: getCurrentTime(),
        }
    },
methods:{
    async updateCurrentTime() {
      this.currentTime = getCurrentTime();
    },
},
created(){
    setInterval(() => this.updateCurrentTime(), 1000);
}
}
</script>

<style scoped>
.time{
    margin: 0;
    color: #8e0404;
    font-weight: 700;
    border: 1px dashed;
   
}

</style>