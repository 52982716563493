<template>
  <router-view/>
</template>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Mali:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Mali:wght@300&family=Noto+Sans+Thai:wght@300&display=swap');
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* font-family: 'Mali', cursive; */
  font-family: 'Noto Sans Thai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.btn{
  border-radius: 21px !important;
  min-width: 40px;
 
}

.btn:hover{
  text-decoration: none !important;
  background: #ffffff00 !important;
  color: #005af0 !important;
  /* padding: 0 !important; */
  margin-left: 0!important;
  /* border: none !important; */
  border: 1px solid #010101;
  transition: 0.5s;
  box-shadow: -2px 2px 3px #00000073;
}
table.dataTable tbody th, table.dataTable tbody td {
    padding: 5px 5px !important;
}

table.table-bordered.dataTable thead tr:first-child th, table.table-bordered.dataTable thead tr:first-child td {
font-size: small;
vertical-align: top;
}
.table > tbody {
  
    font-size: small;
}

.form-control{
border-radius: 0 !important; 
color:var(--bs-gray-700) !important;

} 
.form-control:focus {
color: var(--bs-body-color) !important;
 background-color: #ffd34f !important;
 border-color: #768cad !important;
 outline: 0 !important;
 color:blue !important;
 box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 0%) !important;
 font-weight: 600 !important;
}
</style>
