import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const log = {
  state: {
    datauser: null,
    LoginVerified:false,
  },
  getters: {
    datauser: (state) => state.datauser,
    LoginVerified:(state) => state.LoginVerified,
  },
  actions: {
    LOGIN: ({ commit, state }, payload) => {
      // alert(55555)
      return new Promise((resolve, reject) => {
        axios
          .post(`login`, payload)
      
          .then(({ data, status }) => {
          
            // if(data !='Unsuccess'){

            const userData = data;
       
            commit("setData", {
              uid: userData[0].uid,
              displayName: userData[0].uName,
              displayLastName: userData[0].ulastname,
              EmpNo: userData[0].uid_emp,
              UserAccess: userData[0].ulevel,
              Shift: userData[0].uShift,
              StoreID: userData[0].uStore,
              // LoginVerified: true,
              photoURL: userData[0].uImg,
            });
            commit("setLogin" ,true);
          
        // } 
        if (status === 200) {
            resolve(true);
           
          }
           
       
          })
          .catch((error) => {
           
            console.log(error)
            reject(error);
          });
      });
    },
    LOGOUT: ({ commit, state }) => {
      const data_ad_show = localStorage.getItem("ad_show");
      (state.datauser = null), localStorage.clear();
      (state.LoginVerified = false), localStorage.clear();
      localStorage.setItem("ad_show", data_ad_show);
    },
  },

  mutations: {
    setData(state, data) {
      state.datauser = data;
    },
    setLogin(state, data) {
      state.LoginVerified = data;
    },
  },
};
export default log;
