import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const coupons_map = {
  state: {
    // dataCoupon: null,
  },
  getters: {
    // dataCoupon:state=>state.dataCoupon,
  },
  actions: {
    async couponsMapData({ commit }, payload) {
    
      try {
        const response = await axios
          .post("ControlcouponUsed", payload.from)
          .then((response) => {
            const id = response.data;
            for (const property in payload.cbxs.cbxs_t) {
          
              const datacheck = {
                priodID: payload.cbxs.cbxs_t[property],
                cou_id: id,
              };
              this.dispatch("CouponTimeData", datacheck);
            }
          });

        // commit("setData", response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async UpdateDataCouponsMapID({ commit }, payload) {
    // console.log(payload)
      try {
        const response = await axios
          .put(`ControlcouponUsed/${payload.from.cou_id}`, payload.from)
          .then((response) => {
            ////befor Delete
            this.dispatch("deleteCouponTimeData", payload.from.cou_id).then(
              (success) => {
               
                const id = payload.from.cou_id;
                for (const property in payload.cbxs.cbxs_t) {
                 
                  const datacheck = {
                    priodID: payload.cbxs.cbxs_t[property],
                    cou_id: id,
                  };
                  this.dispatch("CouponTimeData", datacheck);
                }
              }
            );
          });
       
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getcouponsMapData() {
      try {
        const response = await axios.get(
          "ControlcouponUsed"
        );
        // console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async deletecouponsMapData({ commit }, id) {
      console.log(id);
      try {
        const response = await axios.delete(
          `ControlcouponUsed/${id}`
        );
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    // setData(state, dataCoupon) {
    //   state.dataCoupon = dataCoupon;
    // },
  },
};
export default coupons_map;
