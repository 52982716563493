import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const typecoupons = {
  state: {
    // datastore: null,
  },
  getters: {
    // datastore:state=>state.datastore,
  },
  actions: {
    async typecouponsData({ commit }, payload) {
      try {
        const response = await axios.post(
          "ControlTypecoupons",
          payload
        );
      
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async UpdateDataMageCou_typeID({ commit }, payload) {
      try {
        const response = await axios.put(
          `ControlTypecoupons/${payload.cou_typeID}`,
          payload
        );
   
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async gettypecouponsData() {
     
      try {
        const response = await axios.get(
          "ControlTypecoupons"
        );
        console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async deletetypecouponsData({ commit },id) {
      console.log(id)
      try {
        const response = await axios.delete(
          `ControlTypecoupons/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    // setData(state, datastore) {
    //   state.datastore = datastore;
    // },
  },
};
export default typecoupons;
