import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const CouponTime = {
  state: {
    // dataTime: null,
  },
  getters: {
    // dataTime:state=>state.dataTime,
  },
  actions: {
    async CouponTimeData({ commit }, payload) {


      try {
        const response = await axios.post(
          "Controlcoupontime",
         payload
        );
        // console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async UpdateDataCouponTimeID({ commit }, payload) {
      
      try {
        const response = await axios.put(
          `Controlcoupontime/${payload.uid}`,
          payload
        );
        // console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getCouponTimeData() {
     
      try {
        const response = await axios.get(
          "Controlcoupontime"
        );
        // console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async deleteCouponTimeData({ commit },id) {
      try {
        const response = await axios.delete(
          `Controlcoupontime/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    // setData(state, dataTime) {
    //   state.dataTime = dataTime;
    // },
  },
};
export default CouponTime;
