import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const user = {
  state: {
    // data: null,
  },
  getters: {
    // data:state=>state.data,
  },
  actions: {
    async userData({ commit }, payload) {
      
      const formData = new FormData();
      formData.append("uid_emp", payload.image);
      formData.append("uid_emp", payload.uid_emp);
      formData.append("uName", payload.uName);
      formData.append("ulastname", payload.ulastname);
      formData.append("upassword", payload.upassword);
      try {
        const response = await axios.post(
          "controluser",
          payload
        );
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async UpdateDataUserID({ commit }, payload) {
      try {
        const response = await axios.put(
          
          `controluser/${payload.get('uid')}`,
          payload
        );
     
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async UpdateDataUserPasswordByID({ commit }, payload) {
 
    //  console.log(payload.get("uid_emp"))
      try {
        const response = await axios.put(
         
          `controluserpassword/${payload.uid}`,
          payload
        );
        console.log(response.data);
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getuserData() {
     
      try {
        const response = await axios.get(
          "controluser"
        );
       
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async getuserDataById({ commit },ID) {
   
      try {
        const response = await axios.get(
          `ControluserName/${ID}`
        );
     
        return response.data;
       
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async deleteuserData({ commit },id) {
      try {
        const response = await axios.delete(
          `controluser/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async deleteuserData({ commit },id) {
      try {
        const response = await axios.delete(
          `controluser/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async insertUserData({ commit },data) {
      // alert(123)
      console.log('data---->',data)
      try {
        const response = await axios.post(
          `controluserArray`,{data}
        );
        return response.data;
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    // setData(state, data) {
    //   state.data = data;
    // },
  },
};
export default user;
