import axios from "axios";
import { mapState } from "vuex";
import createPersistedState from "vuex-persistedstate";

const shift = {
  state: {
    // datastore: null,
  },
  getters: {
    // datastore:state=>state.datastore,
  },
  actions: {
    async ShiftData({ commit }, payload) {
      try {
        const response = await axios.post(
          "ControlShift",
          payload
        );
   
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async UpdateDataShiftID({ commit }, payload) {
      try {
        const response = await axios.put(
          `ControlShift/${payload.ShiftID}`,
          payload
        );
        
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async getShiftData() {
     
      try {
        const response = await axios.get(
          "ControlShift"
        );
       
        // commit("setData", response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    }, 
    async deleteShiftData({ commit },id) {
   
      try {
        const response = await axios.delete(
          `ControlShift/${id}`
        );
        
      } catch (error) {
        console.log(error.response.data);
      }
    },
  },

  mutations: {
    // setData(state, datastore) {
    //   state.datastore = datastore;
    // },
  },
};
export default shift;
