import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
// import UIModule from "./modules/ui";
import User from "./modules/user";
import Store from "./modules/store";
import Coupons from './modules/coupons';
import Time from './modules/time';
import ac_log from './modules/ac_login';
import typecoupon from './modules/typecoupon';
import Coupon_Time from './modules/coupons_time';
import Coupons_map from './modules/coupons_map'
import action_usecouupon from './modules/ac_coupons';
import shift_user from './modules/shift';
import Store_evaluation from './modules/store_rate';
import Time_Evaluation from './modules/timeEvaluation'
import complaint from './modules/complaint'
import { createStore } from "vuex";
const store = createStore({
  plugins: [createPersistedState()],
  modules: {
    /// 1 Module User

    user: User,
    store: Store,
    coupons: Coupons,
    time:Time,
    ac_loging:ac_log,
    type:typecoupon,
    CouponTime:Coupon_Time,
    CouponsUse:Coupons_map,
    actionCoupon:action_usecouupon,
    shift:shift_user,
    Store_evaluation:Store_evaluation,
    setTime_eva:Time_Evaluation,
    complaint:complaint,
  },
});

// export the store
export default store;
